import apiClient from './apiClient';

// Request to list incoming requests
export const listIncomingRequestsRequest = (data) => {
  const { nextPage = 1} = data || {};
  
  return apiClient({
    method: 'POST',
    url: `/incoming-requests?page=${nextPage}`,
    data
  });
}
  

// Request to search incoming requests
export const searchIncomingRequestsRequest = (query) =>
  apiClient({
    method: 'GET',
    url: '/incoming-requests/search',
    params: { query },
  });

// Request to filter incoming requests
export const filterIncomingRequestsRequest = (filters) =>
  apiClient({
    method: 'POST',
    url: '/incoming-requests/filter',
    data: filters,
  });

// Request to export incoming requests
export const exportIncomingRequestsRequest = (format) =>
  apiClient({
    method: 'GET',
    url: `/incoming-requests/export`,
    params: { format },
  });

// Request to get incoming request info
export const getIncomingRequestInfoRequest = (id) =>
  apiClient({
    method: 'GET',
    url: `/incoming-requests/${id}`,
  });

// Request to update incoming request info
export const updateIncomingRequestInfoRequest = (id, data) =>
  apiClient({
    method: 'PUT',
    url: `/incoming-requests/${id}`,
    data,
  });

// Request to requery incoming request
/* export const requeryIncomingRequestRequest = (data) => {
  const {id, ...payload} = data || {};

  return apiClient({
    method: 'POST',
    url: `/incoming-requests/${id}/requery`,
    data: payload,
  });
} */

  export const requeryIncomingRequestRequest = (e, data) => {
    const { id } = e || {};

    return apiClient({
      method: 'POST',
      url: `/incoming-requests/${id}/requery`
    });
  }


// Request to add an incoming request
export const addIncomingRequestRequest = (data) =>
  apiClient({
    method: 'POST',
    url: '/incoming-requests',
    data,
  });
