// Selector to get the entire incomingRequests state
export const selectIncomingRequestsState = (state) => state.incomingRequests;

// Selector to get the loading status for incomingRequests actions
export const selectIncomingRequestsLoading = (state) => state.incomingRequests.loading;

// Selector to get the error for incomingRequests actions
export const selectIncomingRequestsError = (state) => state.incomingRequests.error;

// Selector to get the list of incoming requests (includes filtered or search results)
export const selectIncomingRequestsData = (state) => state.incomingRequests?.incomingRequests?.data ?? [];

/* // Selector to get the current page for pagination
export const selectIncomingRequestsCurrentPage = (state) => state.incomingRequests.incomingRequests.currentPage;

// Selector to get the total number of records
export const selectIncomingRequestsTotalRecords = (state) => state.incomingRequests.incomingRequests.totalRecords;

// Selector to get the number of items per page
export const selectIncomingRequestsPageSize = (state) => state.incomingRequests.incomingRequests.pageSize; */

export const selectIncomingRequestsPagination = (state) => ({
    currentPage: state.incomingRequests.incomingRequests.currentPage,
    totalRecords: state.incomingRequests.incomingRequests.totalRecords,
    pageSize: state.incomingRequests.incomingRequests.pageSize,
});

// Selector to get the loading status for export action
export const selectIncomingRequestsExportLoading = (state) => state.incomingRequests.export.loading;

// Selector to get the success status for export action
export const selectIncomingRequestsExportSuccess = (state) => state.incomingRequests.export.success;

// Selector to get the error for export action
export const selectIncomingRequestsExportError = (state) => state.incomingRequests.export.error;

// Selector to get the incoming request details
export const selectIncomingRequestDetails = (state) => state.incomingRequests.viewDetails.data;

// Selector to get the error for incoming request details action
export const selectIncomingRequestDetailsError = (state) => state.incomingRequests.viewDetails.error;

// Selector to get the success status for updating incoming request info
export const selectIncomingRequestUpdateInfoSuccess = (state) => state.incomingRequests.updateInfo.success;

// Selector to get the error for updating incoming request info action
export const selectIncomingRequestUpdateInfoError = (state) => state.incomingRequests.updateInfo.error;

// Selector to get the success status for updating incoming request balance
export const selectIncomingRequestRequerySuccess = (state) => state.incomingRequests.requery.success;

// Selector to get the error for updating incoming request balance action
export const selectIncomingRequestRequeryError = (state) => state.incomingRequests.requery.error;

export const selectIncomingRequestRequeryLoading = (state) => state.incomingRequests.requery.loading;

// Selector to get the success status for adding an incoming request
export const selectAddIncomingRequestSuccess = (state) => state.incomingRequests.addProvider.success;

// Selector to get the error for adding an incoming request action
export const selectAddIncomingRequestError = (state) => state.incomingRequests.addProvider.error;
