import { Card, Table } from "antd";
import React, { useEffect } from "react";
import Filterbox from "../../ui/FilterBox/Filterbox";
import SapaHeader from "../../ui/Header/Header";
import { incomingRequestsColumn } from "../../DummyAPI/TableData/Columns";
import ProgressLoader from "../../components/Loader/ProgressLoader";
import { useDispatch, useSelector } from "react-redux";
import { selectIncomingRequestRequeryLoading, selectIncomingRequestsData, selectIncomingRequestsLoading, selectIncomingRequestsPagination } from '../../redux/selectors/incomingRequestSelectors';
import { formatDate } from "../../utils/utils";
import { listIncomingRequests } from "../../redux/actions/incomingRequest";
import SpPagination from "../../components/SpPagination/SpPagination";

const IncomingRequests = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIncomingRequestsLoading);
  const requeryLoading = useSelector(selectIncomingRequestRequeryLoading);
  const providerList = useSelector(selectIncomingRequestsData);
  const incomingRequestPagination = useSelector(selectIncomingRequestsPagination);
  const { totalRecords = 20, pageSize = 10, currentPage = 1 } = incomingRequestPagination || {};

  const tableStyle = {
    tableLayout: "fixed",
  };

  // adding classname to odd table rows
  const rowClassName = (record, index) => {
    return index % 2 === 1 ? "odd-row" : "";
  };

  useEffect(() => {
    dispatch(listIncomingRequests());
  }, [dispatch]);

  const prepareDataToExport = () => {
    let data = providerList.map(item => {
      return {
        "Provider Name": item?.name,
        "Description": item?.description,
        "Date": formatDate(item?.created_at)
      };
    });

    return data;
  };


  return (
    <ProgressLoader isLoading={isLoading || requeryLoading}>
      <div className="IncomingRequests">
    
        <SapaHeader page={"Incoming Requests"} />
        <Card className="sapa-IncomingRequests__container">
          <Filterbox 
            displayFilter={"none"}
            searchIsLoading={isLoading}
            searchAction={listIncomingRequests}
            exportFileName="incoming_requests.csv"
            dataToExport={prepareDataToExport}
            showExportButton={false}
          />
          <Table
            dataSource={providerList}
            columns={incomingRequestsColumn}
            pagination={false}
            rowClassName={rowClassName}
            style={tableStyle}
          />

          <SpPagination
                defaultCurrent={currentPage}
                total={totalRecords}
                pageSize={pageSize}
                onChange={(value) => {
                    dispatch(listIncomingRequests({ nextPage: value }));
                }}
            />
        </Card>
      </div>
    </ProgressLoader>
  );
};

export default IncomingRequests;
