import { Table } from "antd";
import React, { useEffect } from "react";
import Filterbox from "../../ui/FilterBox/Filterbox";
import { usersColumn } from "../../DummyAPI/TableData/Columns";
import { useDispatch, useSelector } from "react-redux";
import { selectUsers, selectUsersLoading, selectUsersPagination } from './../../redux/selectors/userSelectors';
import ProgressLoader from "../../components/Loader/ProgressLoader";
import { listUsers } from './../../redux/actions/user';
import { formatDate } from "../../utils/utils";
import SpPagination from "../../components/SpPagination/SpPagination";


const UserList = () => {
    const dispatch = useDispatch();

    const users = useSelector(selectUsers);
    const isLoading = useSelector(selectUsersLoading);
    const userPagination = useSelector(selectUsersPagination);

    const { totalRecords = 20, pageSize = 10, currentPage = 1 } = userPagination || {};

    const tableStyle = {
        tableLayout: "fixed",
    };

    // adding classname to odd table rows
    const rowClassName = (record, index) => {
        return index % 2 === 1 ? "odd-row" : "";
    };

    useEffect(() => {
        dispatch(listUsers());
    }, [dispatch]);

    const columnKeys = usersColumn.map((column) => column.key);

    const prepareDataToExport = () => {
        let data = users.map(item => {

            const profile = item?.user_profile;
            let name = 'Guest User';

            if (profile) {
               name = (profile?.first_name?? '') + " " + (profile?.last_name?? '')
            }

            return {
                "Full Name": name,
                "Account ID": item?.account_id,
                "Email": item?.email,
                "Phone Number": item?.phone_number,
                "Balance": item?.wallet?.main_balance,
                "Date Registered": formatDate(item?.created_at) + ""
            };
        });

        return data;
    };


    return (
        <ProgressLoader isLoading={isLoading}>
            <Filterbox
                type={"usersType"}
                filterType={"Status, Tier or Date"}
                searchIsLoading={isLoading}
                searchAction={listUsers}
                applyFilterAction={listUsers}
                exportFileName="users.csv"
                dataToExport={prepareDataToExport}
            />
            
            <Table
                key={columnKeys}
                dataSource={users}
                columns={usersColumn}
                pagination={false}
                rowClassName={rowClassName}
                style={tableStyle}
            />

            <SpPagination
                defaultCurrent={currentPage}
                total={totalRecords}
                pageSize={pageSize}
                onChange={(value) => {
                    dispatch(listUsers({ nextPage: value }));
                }}
            />

        </ProgressLoader>
    );
};

export default UserList;
